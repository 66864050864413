import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Card, Input, Select, Button, Space, Switch, message, Modal } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import Service from 'services/EprescriptionMedicamentService';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import { useHistory } from 'react-router-dom';


const Details = (props) => {
    const [state] = useState(props.location.state);
    const [form] = Form.useForm();
    const [submitLoading, setSumbitLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    const history = useHistory();


    const onFinish = () => {
        form.validateFields().then(async values => {
            setSumbitLoading(true);
            if (state) {
                await Service.update(values, state.id);
                message.success("Modification enrigistre avec success")


            } else {
                await Service.create(values);
                message.success("enrigistre avec success")


            }
            setSumbitLoading(false)
        })
    }

    useEffect(() => {
        form.setFieldsValue(state)
    }, [])
    return (<Card title={state ? "Modifier" : "Creer Un Medicament"
    } extra={< Space >{state && (<Button onClick={() => setVisible(true)} shape='circle' danger icon={<DeleteOutlined />} />)} <Button onClick={() => onFinish()} loading={submitLoading} type='primary'>Sauvegarder</Button></Space>}>


        <Modal visible={visible} confirmLoading={modalLoading} onCancel={() => setVisible(false)} onOk={async () => {
            setModalLoading(true)
            await Service.delete(state);
            message.success("supprimer successivement");

            history.push({
                pathname: `${APP_PREFIX_PATH}/eprescription/new-medics`
            });
        }}>
            <h3>Voulez vous vraiment supprimer ce Medicament?</h3>
        </Modal>

        <Form layout='vertical' form={form}>
            <Row gutter={16} justify="space-between">
                <Col span={8}>
                    <Form.Item name="nomcommercial" label="Nom Comercial">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="prix" label="prix">
                        <Input />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item name="approved" label="approved?">
                        <Switch />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="dci" label="Dci">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="formeDosage" label="Forme de dosage">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>


                <Col span={12}>
                    <Form.Item name="specialite" label="specialite">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item name="classePharmaceutique" label="Classe Pharmaceutique">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="indication" label="Indication">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="contreIndication" label="Contre Indication">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="reference" label="Reference">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="grossesseAllaitement" label="Grossesse/Allaitement">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>
            </Row>


            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item name="MAJministereSante" label="MAJ Ministere Sante">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item name="remboursementCNAM" label="remboursementCNAM">
                        <Input.TextArea rows={6} />
                    </Form.Item>
                </Col>
            </Row>

        </Form>

    </Card >)
}

export default Details;



