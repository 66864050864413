export const data = [
  {
    id: 1,
    titleFr: "Online Consulting",
    titleAr: "",
    slug: "online",
    endpoint: "online-consulting",
  },
  {
    id: 2,
    titleFr: "Office Consulting",
    titleAr: "",
    slug: "office",
    endpoint: "office-consulting",
  },
];
