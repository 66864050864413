import { DEFAULT_PAGE_SIZE, MEDICS_DELETE, MEDICS_LIST } from "constants/ApiConstant"
import fetch from "../auth/FetchInterceptor";

const Service = {}


Service.findAll = async (pageNo, size, search, unApproved = false) => {
    const url = MEDICS_LIST + `?pageNo=${pageNo}&pageSize=${size}&name=${search}&approved=${unApproved}`
    return await fetch.get(url).then(response => response)
}

Service.create = async (new_medic) => {
    const url = MEDICS_LIST
    return await fetch.post(url, new_medic).then(response => response)
}
Service.update = async (new_medic, id) => {
    const url = MEDICS_LIST + `${id}`
    console.log(url, new_medic)
    new_medic['id'] = id;
    return await fetch.post(url, new_medic).then(response => response)
}

Service.delete = async (medic) => {
    const url = MEDICS_DELETE + `/${medic.id}`
    console.log(url, medic)
    return await fetch.post(url).then(response => response)
}

export default Service;