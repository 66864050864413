import { message } from "antd";
import { GEEKBLUE, GREEN } from "constants/Colors";
import { DISABLE, ENABLE } from "constants/contentText";
import { useFetchDoctorById } from "zustand/store/doctorStore";

export function alertMessage(messageContent, status) {
  switch (status) {
    case "success":
      return message.success(messageContent);
    case "warning":
      return message.warning(messageContent);
    default:
      return message.error(messageContent);
  }
}

export function onChangeStatus(serviceName) {
  const doctor = useFetchDoctorById.getState().doctor;

  const isChecked = useFetchDoctorById.getState().hasChecked(serviceName);

  if (isChecked) {
    return useFetchDoctorById
      .getState()
      .enableAppointmentByServiceName(doctor.id, serviceName);
  } else {
    return useFetchDoctorById
      .getState()
      .disableAppointmentByServiceName(doctor.id, serviceName);
  }
}

export function renderTextButtonByStatus(status) {
  console.log("1===>", status);
  switch (status) {
    case "SCHEDULED":
    case "SUBMITTED":
    case "CONFIRMED":
      return "Annuler";
    default:
      return "";
  }
}

export function renderButtonColor(status) {
  console.log("2===>", status);
  switch (status) {
    case "SCHEDULED":
    case "SUBMITTED":
    case "CONFIRMED":
      return "danger";

    default:
      return "primary";
  }
}

export const renderColorTag = (status) => {
  switch (status) {
    case "SCHEDULED":
      return GREEN;
    default:
      return GEEKBLUE;
  }
};

export const renderTextTag = (status) => {
  switch (status) {
    case "SCHEDULED":
      return DISABLE;
    default:
      return ENABLE;
  }
};

export const renderContentTag = (status) => {
  switch (status) {
    case "SCHEDULED":
      return "Programmé";
    case "CONFIRMED":
      return "Confirmé";
    case "AWAITING_PAYMENT_APPROVAL":
      return "Paiement en attente";
    case "CANCELED":
      return "Annulé";
    case "ATTENDED":
      return "Présent";
    case "MISSED":
      return "Manqué";
    case "CLOSED":
      return "Clôturé";
    default:
      return "En attente";
  }
};

export const createArrayListOfOptions = (options) => {
  const list = [];
  options.forEach((element) => {
    list.push({
      name: element?.name,
      value: element?.name,
      id: element.id,
    });
  });

  return list;
};

export const createArrayList = (options) => {
  const list = [];
  options.forEach((element) => {
    list.push({
      label: element?.enterpriseName,
      value: element?.enterpriseId,
      id: element?.enterpriseId,
    });
  });

  return list;
};

export const splitWord = (text) => {
  const splittedTex = text.split("@");
  return splittedTex[0];
};

export const calculateOutstandingAmount = (
  feeAmount,
  amount,
  outStandingAmount
) => {
  const total = feeAmount + amount - outStandingAmount;
  return parseFloat(total);
};

export const goBack = () => window.history.back();

export const handleAppointmentServiceName = (serviceName) => {
  switch (serviceName) {
    case "onlineService":
      return true;
    default:
      return false;
  }
};

export const getFileExtension = (fileName) => {
  const match = fileName.match(/\.(\w+)(?:\?.*)?$/);
  return match ? match[1] : "";
};

export const getFileType = (fileName) => {
  console.log("filename", fileName)
  switch (getFileExtension(fileName)) {
    case "png":
    case "PNG":
    case "webp":
    case "jpg":
    case "jpeg":
    case "jfif":
    case "pjpeg":
    case "pjp":
    case "svg":
    case "psd":
    case "tiff":
    case "tif":
    case "bmp":
    case "dib":
    case "arw":
    case "cr2":
    case "nrw":
    case "k25":
    case "svgz":
    case "ai":
    case "eps":
    case "jp2":
    case "j2k":
    case "jpf":
    case "jpx":
    case "jpm":
    case "mj2":
    case "heif":
    case "heic":
    case "ind":
    case "indd":
    case "indt":
      return "IMAGE";

    case "pdf":
    case "docx":
    case "doc":
    case "odt":
    case "xls":
    case "xlsx":
    case "ods":
    case "ppt":
    case "pptx":
    case "txt":
      return "DOCUMENT";

    case "mp3":
    case "m4a":
    case "m4b":
    case "aa":
    case "aac":
    case "aax":
    case "act":
    case "aiff":
    case "alac":
    case "amr":
    case "ape":
    case "au":
    case "flac":
    case "gsm":
    case "m4p":
    case "movpkg":
    case "opus":
    case "ra":
    case "rm":
    case "raw":
    case "wav":
    case "wma":
    case "wv":
    case "webm":
    case "cda":
    case "rf64":
    case "ogg":
      return "AUDIO";

    default:
      return "VIDEO";
  }
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export const convertToBase64 = async (file) => {
  const base64 = await Promise.all(
    file.map(async (file) => {
      if (file.originFileObj) {
        const base64 = await getBase64(file.originFileObj);
        return {
          name: file.name,
          base64,
        };
      }
      return file;
    })
  );
  return base64;
};
