import {
  GET_MEDECIN,
  ENABLE_SERVICE,
  ACTIVATE_SERVICE_QUERY_PARAM,
  DEACTIVATE_SERVICE_QUERY_PARAM,
  DOCTOR_ENTERPRISES,
  ACTION_ON_ENTERPRISES,
  SWITCH_ACTION,
  DOCTOR_SCHEDULE,
} from "constants/ApiConstant";
import { create } from "zustand";
import fetch from "../../auth/FetchInterceptor";
import { alertMessage } from "hooks/useUtils";
import { data } from "views/app-views/doctors/EnableServices/serviceData";
import {
  ACTIVATE,
  DEACTIVATE,
  ERROR,
  OPERATION_SUCCESS,
} from "constants/contentText";

export const useFetchDoctorById = create((set, get) => ({
  idDoctor: "",
  doctor: {},
  loading: false,
  error: null,
  listService: data,
  listEnterprisesByDoctorId: [],
  listAvailableEnterprise: [],
  dataSchedule: [],
  listOfBlockedOfficeConsulting: [],
  actionConfirmed: false,
  setActionConfirmed: () => {
    console.log("keyklock");
    set({ actionConfirmed: true });
  },
  setDoctorIdFromUrl: () => {
    const path = window.location.pathname;  
    const id = path.split('/').pop();  
    set({ idDoctor: id }); 
  },

  setListOfOfficeConsultingBlock: (values) =>
    set((state) => ({
      listOfBlockedOfficeConsulting: [
        ...state.listOfBlockedOfficeConsulting,
        values,
      ],
    })),

  cleanList: () => set({ listOfBlockedOfficeConsulting: [] }),

  handleOnlineConsulting: async (status)  => {
    set({ loading: true });

    const id = window.location.href.split("/").pop();
    fetch

      .patch(
        `/yata/doctor/${id}/suspend-online-consulting?block=${!status}`
      )
      .then(() => {
        alertMessage(
          `Rendez-vous en ligne est ${status ? DEACTIVATE : ACTIVATE}`,
          "success"
        );
        set((state) => {
          state.getDoctor(id);
          state.loading = false;
          return state;
        });
      })
      .catch((error) => {
        console.log(error?.message);
        set({ loading: false }); 
      });
  },
  

  handleOfficeConsultingBlock: (status) => {
    set({ loading: true });
    const listOfEnterprise = get().listOfBlockedOfficeConsulting;
    console.log("hello i am here ")
    const url =
      listOfEnterprise.length === 1
        ? `/v2/yata/${listOfEnterprise[0]}/${
            get().idDoctor
          }/block-from-consulting-in-enterprise?block=${!status}`
        : `/v2/yata/${get().idDoctor}/block-from-consulting?block=${!status}`;

    fetch
      .patch(url)
      .then(() => {
        alertMessage(`Rendez-vous au cabinet est  `, "success");
        set((state) => {
          state.getDoctor(get().idDoctor);
          state.loading = false;
          state.actionConfirmed = false;
          return state;
        });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },

  getDoctor: (id) => {
    const url = GET_MEDECIN + id;
    set({ loading: true, error: null });

    fetch
      .get(url)
      .then((response) => {
        console.log("===> Services", response);
        set({
          doctor: response.reverse(),
          loading: false,
          idDoctor: id,
        });
      })
      .catch((error) => set({ error: error }));
  },

  getEnterprisesByDoctorId: (id) => {
    set({ loading: true });
    fetch
      .get(`${DOCTOR_ENTERPRISES}?doctorId=${id}`)
      .then((response) => {
        set({
          listEnterprisesByDoctorId: response.reverse(),
          listAvailableEnterprise: response.filter(
            (item) => item.isBlockedFromConsultation === false
          ),
          loading: false,
        });
      })
      .catch((error) => set({ error: error, loading: false }));
  },

  handleEnterpriseStatus: (status, id) => {
    set({ loading: true });

    const urlStatus = `/v2/yata/${id}/${
      get().idDoctor
    }/block-from-consulting-in-enterprise?block=${!status}`;

    fetch
      .patch(urlStatus)
      .then(() => {
        alertMessage(OPERATION_SUCCESS, "success");

        set((state) => {
          state.getEnterprisesByDoctorId(state.idDoctor);
          state.loading = false;
          return state;
        });
      })
      .catch(() => alertMessage(ERROR, "error"));
  },

  doctorSchedule: (id) => {
    set({ loading: true });
    fetch
      .get(`${DOCTOR_SCHEDULE}/${id}`)
      .then((response) => {
        set({ dataSchedule: response });
      })
      .catch((error) => set({ loading: false, error: error }));
  },
}));
